<template>
  <CAtomsLink class="button">
    <slot />
  </CAtomsLink>
</template>

<style lang="scss">
.c-link {
  &.button {
    display: inline-flex;
    padding: 0.55em 1em;
    align-items: center;

    &.center {
      display: flex;
      margin-left: auto;
      margin-right: auto;
    }
    &.content-button,
    &.form-submit {
      display: flex;
      margin: 2rem auto;
    }

    .c-link {
      &-text {
        margin-bottom: var(--button-text-offset);
      }
      &-icon {
        margin-bottom: var(--button-icon-offset);
      }
    }
  }

  &:disabled,
  &[disabled],
  &.disabled {
  }

  &:hover,
  &:focus,
  &:active,
  &.active {
    // &::before {
    //   transform: skew(-14deg) translateX(150%);
  }
}
</style>
